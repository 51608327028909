import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import "react-input-range/lib/css/index.css"
import Masonry from "react-masonry-component"
import CreateBoardModal from "../../components/ContentBuilder/Modal/CreateBoardModal"
import { isLoggedIn, getUser } from "../../services/auth"
import QuickViewModalData from "../../components/ContentBuilder/Modal/QuickViewModalData"

const FeaturedProducts = () => {
  return <AllItems />
}

export default FeaturedProducts
    
function AllItems() {    
    const allItemsRaw = useStaticQuery(graphql`
    {
      allSilverStripeDataObject(filter:
        {className: {eq: "Undigital__Objects__FeaturedProduct"}}, 
        sort: {fields: UndigitalFeaturedProduct___sortOrder, order: ASC}) {
        nodes {
          UndigitalFeaturedProduct {
            title
            showTitleOnTile
            tileBlackTitle
            description
            link
            imagePath
            imagePosition
            sortOrder
            imageID
            objectID
          }
        }
      }
    }
  `);
    const allItemsNodes = allItemsRaw.allSilverStripeDataObject.nodes;
    var allItems = [];
    var i = 0;
    var item1, item2, item3;
    for (; allItemsNodes[i]; ) {      
        var option = allItemsNodes[i].UndigitalFeaturedProduct;
        option.about = option.description;
        option.name = option.title;
        option.className = 'FeaturedProduct';
        if(i==0){
            item1 = option;
        }else if(i==1){
            item2 = option;
        }else if(i==2){
            item3 = option;
        }
        if(i>2){
            allItems.push(option)
        }
        i++;
    }
    
    const [showQuickView, setShowQuickView] = useState(false);
    const [quickViewData, setQuickViewData] = useState(null);
    const setUpQuickViewModal = (e, item) => {
        e.preventDefault();
        setQuickViewData(item);
        setShowQuickView(true);
    }
  return (
    <>
    <Masonry className="grid has-big-item" options={{ columnWidth: '.grid-sizer', transitionDuration: 0, percentPosition: true }}>
        <div className="grid-sizer w-25"></div>
        <div className={`grid-item w-25 ${item1.showTitleOnTile == 1 ? ' showtitle' : ''} ${item1.imagePosition} ${item1.tileBlackTitle == 1 ? 'text-black' : ''}`}>
            <div>
              <a href={item1.link} className="grid-item-inner">
                {item1.imagePath && (
                    <img src={item1.imagePath} alt="" />
                )}
                <div className="grid-caption">
                    {item1.title && (
                    <h4>{item1.title}</h4>
                    )}
                  {item1.description && (
                        <p>{item1.description}</p>
                  )}
                </div>
              </a>
            </div>
        </div>
        <div className={`grid-item w-25 d-block d-md-none ${item3.showTitleOnTile == 1 ? ' showtitle' : ''} ${item3.imagePosition} ${item3.tileBlackTitle == 1 ? 'text-black' : ''}`}>
            <div>
              <a href={item3.link} className="grid-item-inner">
                {item3.imagePath && (
                    <img src={item3.imagePath} alt="" />
                )}
                <div className="grid-caption">
                    {item3.title && (
                    <h4>{item3.title}</h4>
                    )}
                  {item3.description && (
                        <p>{item3.description}</p>
                  )}
                </div>
              </a>
            </div>
        </div>
        <div className={`grid-item w-50 d-lg-block d-xl-none ${item2.showTitleOnTile == 1 ? ' showtitle' : ''} ${item2.imagePosition} ${item2.tileBlackTitle == 1 ? 'text-black' : ''}`}>
            <div>
              <a href={item2.link} className="grid-item-inner">
                {item2.imagePath && (
                    <img src={item2.imagePath} alt="" />
                )}
                <div className="grid-caption">
                    {item2.title && (
                    <h4>{item2.title}</h4>
                    )}
                  {item2.description && (
                        <p>{item2.description}</p>
                  )}
                </div>
              </a>
            </div>
        </div>
        <div className={`grid-item w-25 d-none d-sm-block ${item3.showTitleOnTile == 1 ? ' showtitle' : ''} ${item3.imagePosition} ${item3.tileBlackTitle == 1 ? 'text-black' : ''}`}>
            <div>
              <a href={item3.link} className="grid-item-inner">
                {item3.imagePath && (
                    <img src={item3.imagePath} alt="" />
                )}
                <div className="grid-caption">
                    {item3.title && (
                    <h4>{item3.title}</h4>
                    )}
                  {item3.description && (
                        <p>{item3.description}</p>
                  )}
                </div>
              </a>
            </div>
        </div>
        <div className={`grid-item w-50 d-xl-block d-none ${item2.showTitleOnTile == 1 ? ' showtitle' : ''} ${item2.imagePosition} ${item2.tileBlackTitle == 1 ? 'text-black' : ''}`}>
            <div>
              <a href={item2.link} className="grid-item-inner">
                {item2.imagePath && (
                    <img src={item2.imagePath} alt="" />
                )}
                <div className="grid-caption">
                    {item2.title && (
                    <h4>{item2.title}</h4>
                    )}
                  {item2.description && (
                        <p>{item2.description}</p>
                  )}
                </div>
              </a>
            </div>
        </div>
      {allItems.map((item, idx) => (
        <div key={`feature-product-${item.objectID}`} className={`aaa grid-item ${item.showTitleOnTile == 1 ? ' showtitle' : ''} ${item.imagePosition} ${item.tileBlackTitle == 1 ? 'text-black' : ''}${idx === 2 ? ' w-50' : ' w-25'}`}>
            <div>
              <a href={item.link} className="grid-item-inner">
                {item.imagePath && (
                    <img src={item.imagePath} alt="" />
                )}
                <div className="grid-caption">
                    {item.title && (
                    <h4>{item.title}</h4>
                    )}
                  {item.description && (
                        <p>{item.description}</p>
                  )}
                </div>
              </a>
            </div>
        </div>
      ))}
    </Masonry>
    {quickViewData !== null && (
    <QuickViewModalData 
        show={showQuickView} 
        handleClose={() => setShowQuickView(false)} 
        hidePricingGlobally={0}
        item={quickViewData}/>
    )}
    </>
  )
}
